/* tab start */
/*
 CSS for the main interaction
*/
/*
 Styling
*/
/*
 Demo purposes only
*/
/* tab end */
/* more info */
@keyframes "open" {
	0% {
		opacity: 0;
		transform: translateY(-10px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
/* states tab start here */
.pipelines {
	.xl65 {
		padding: 3px;
	}
	.flex-grid {
		&:nth-child(odd) {
			background: aliceblue;
			border: 1px solid;
			border-top: 0px;
		}
		flex-wrap: wrap;
	}
	section {
		&:first-child {
			padding-top: 30px;
		}
		padding: 0 0 10px 0;
	}
	.break {
		flex-basis: 100%;
		height: 0;
	}
	.flex100 {
		width: 100% !important;
	}
	.tabset {
		>input[type="radio"] {
			position: absolute;
			left: -200vw;
		}
		.tab-panel {
			display: none;
		}
		>input {
			&:first-child {
				&:checked {
					~ {
						.tab-panels {
							>.tab-panel {
								&:first-child {
									display: block;
								}
							}
						}
					}
				}
			}
			&:nth-child(3) {
				&:checked {
					~ {
						.tab-panels {
							>.tab-panel {
								&:nth-child(2) {
									display: block;
								}
							}
						}
					}
				}
			}
			&:nth-child(5) {
				&:checked {
					~ {
						.tab-panels {
							>.tab-panel {
								&:nth-child(3) {
									display: block;
								}
							}
						}
					}
				}
			}
			&:nth-child(7) {
				&:checked {
					~ {
						.tab-panels {
							>.tab-panel {
								&:nth-child(4) {
									display: block;
								}
							}
						}
					}
				}
			}
			&:nth-child(9) {
				&:checked {
					~ {
						.tab-panels {
							>.tab-panel {
								&:nth-child(5) {
									display: block;
								}
							}
						}
					}
				}
			}
			&:nth-child(11) {
				&:checked {
					~ {
						.tab-panels {
							>.tab-panel {
								&:nth-child(6) {
									display: block;
								}
							}
						}
					}
				}
			}
			&:focus {
				+ {
					label {
						color: #06c;
						&::after {
							background: #06c;
						}
					}
				}
			}
			&:checked {
				+ {
					label {
						&::after {
							background: #06c;
						}
						border-color: #ccc;
						border-bottom: 1px solid #fff;
						margin-bottom: -1px;
						background: #003c71;
						color: white;
					}
				}
			}
		}
		text-align: center;
		>label {
			position: relative;
			display: inline-block;
			padding: 12px 75px 12px;
			border: 2px solid #efefef;
			border-radius: 4px;
			cursor: pointer;
			font-weight: 600;
			&:hover {
				color: #06c;
				&::after {
					background: #06c;
				}
			}
		}
	}
	.tab-panels {
		text-align: left;
	}
	.tab-panel {
		padding: 30px 0;
	}
	.content-box {
		width: 100%;
		margin: 7px;
	}
	h2 {
		display: inline;
	}
	details {
		position: relative;
		summary {
			display: block;
			cursor: pointer;
			color: #003c71;
			&:focus {
				outline: none;
			}
		}
		#open {
			padding-left: 5px;
			text-align: middle;
			&:after {
				display: inline-block;
				position: relative;
				top: -3px;
				padding-left: 8px;
				content: "\00bb";
				transform: rotate(90deg);
				font-size: 14px;
			}
		}
		#close {
			display: none;
		}
	}
	details[open] {
		display: block;
		padding-bottom: 25px;
		padding-top: 10px;
		animation: open .2s linear;
		summary {
			position: absolute;
			bottom: 0;
			left: 0;
		}
		#open {
			display: none;
		}
		#close {
			display: block;
			margin: 14px 7px 0px;
			text-decoration: underline;
		}
	}
	.accordion {
		background: #fff;
		overflow: hidden;
		width: 100%;
		margin-bottom: 10px;
		+ {
			p {
				color: rgba(255, 255, 255, 0.8);
				font-weight: 300;
				font-size: 0.9rem;
				margin-top: 2rem;
			}
		}
	}
	.accordion-tabs {
		display: none;
		&:focus {
			outline: none;
		}
	}
	.accordion-item {
		border-bottom: 1px solid #eaeaea;
		&:last-child {
			border: none;
		}
	}
	.accordion-item__label {
		margin: 0;
		padding: 1.25rem;
		cursor: pointer;
		transition: padding 0.2s ease;
		position: relative;
		&:after {
			content: "";
			height: 4px;
			width: 4px;
			position: absolute;
			top: -4px;
			right: 1.25rem;
			bottom: 0;
			margin: auto;
			border: 3px solid transparent;
			border-radius: 2px;
			transform: rotate(45deg);
			border-color: transparent #447fff #447fff transparent;
			opacity: 1;
			transition: opacity 0.1s ease;
		}
		&:hover {
			background: #d3e1ff;
		}
	}
	.accordion-item__container {
		padding: 0 1.25em;
		height: 0;
		overflow: hidden;
		opacity: 0;
		transition: padding 0.2s ease, opacity 0.5s 0.15s ease;
	}
	.accordion-active {
		background: #fff;
		.accordion-item__label {
			padding-bottom: 0;
			cursor: inherit;
			&:hover {
				background: none;
			}
			&:after {
				opacity: 0;
			}
		}
		.accordion-item__container {
			padding: 1.25em;
			height: auto;
			opacity: 1;
			p {
				&:first-child {
					margin-top: 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
			h1 {
				&:first-child {
					margin-top: 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
			h2 {
				&:first-child {
					margin-top: 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
			h3 {
				&:first-child {
					margin-top: 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
			h4 {
				&:first-child {
					margin-top: 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
			h5 {
				&:first-child {
					margin-top: 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
			h6 {
				&:first-child {
					margin-top: 0;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
* {
	box-sizing: border-box;
	&:before {
		box-sizing: border-box;
	}
	&:after {
		box-sizing: border-box;
	}
}
details {
	display: inline;
}
@media screen and (min-width: 1024px) {
	.pipelines {
		.flex-grid-item {
			&:nth-child(2) {
				width: 30%;
			}
			border-top: 2px solid #f3f6f9;
			width: 19%;
		}
		.flex-grid.header {
			display: flex;
			border-top: 1px solid !important;
		}
		.flex-grid {
			border-left: 1px solid;
			border-right: 1px solid;
			border-bottom: 1px solid;
		}
	}
}
@media screen and (max-width: 767px) {
	.pipelines {
		.tabset {
			>label {
				padding: 12px 28px 12px !important;
			}
		}
	}
}
@media (min-width: 1025px) {
	.pipelines {
		.accordion-tabs {
			display: flex;
			.accordion-tab {
				background: #fff;
				padding: 1rem 2rem;
				font: inherit;
				flex: 1;
				border: 2px solid #efefef;
				cursor: pointer;
				transition: background 0.1s ease;
				font-size: 14px;
				color: #003c71;
				font-weight: bold;
				&:hover {
					background: rgba(249, 249, 249, 0.01);
				}
			}
			.accordion-tab.accordion-active {
				background: #003c71;
				font-size: 14px;
				color: #fff;
				font-weight: bold;
			}
		}
		.accordion-item {
			padding: 2.4rem;
			display: none;
			border: none;
			background: #fff;
			min-height: 260px;
		}
		.accordion-item__label {
			padding: 0;
			transition: inherit;
			margin-bottom: 1.5rem;
		}
		.accordion-item.accordion-active {
			display: block;
			.accordion-item__container {
				padding: 0;
			}
		}
	}
	.accordion-item__container {
		padding: 0;
		transition: inherit;
	}
}

.card-grid {
  padding-top: 50px;
  &.card-outer-space-fix {
    padding: 12px !important;
    margin-top: 20px;
  }
  @include media($desktop) {
    padding-top: 38px;
  }

  .container > div {
    margin: 0 24px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    > a {
      width: 100%;
    }

    @include media($desktop) {
      flex-wrap: nowrap;
      margin-left: -32px;
      margin-right: -32px;

      > a {
        padding-left: 32px;
        padding-right: 32px;
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
    }
  }

  hr {
    border-bottom: 5px solid $dark-blue;
    margin: 0 0 24px;
  }

  &__title {
    font-weight: 800;
    font-family: $albert-extra-bold;
    line-height: 1.2;
    margin-bottom: 16px;
    font-size: 36px;

    @include media($desktop) {
      font-size: 54px;
    }
  }

  &__content {
    margin-bottom: 32px;
  
    p {
      font-weight: 300;
      line-height: 1.4;
      margin-bottom: 24px;
      font-size: 18px;

      @include media($desktop) {
        font-size: 24px;
      }

      &:last-of-type {
        margin: 0;
      }
    }
  }

  .btn {
    margin-top: 32px;

    @include media($desktop) {
      margin-top: 64px;
    }
  }
}

@media screen and (min-width: 800px){
	.pd {
		padding-top:0px;
	}
	.pd-news {
		padding-top:0px;
	}
}
@media screen and (min-width: 1000px){
	.pd {
		padding-top:0px;
	}
	.pd-news {
		padding-top:50px;
	}
}
@media screen and (min-width: 1100px){
	.pd {
		padding-top:110px;
	}
	.pd-news {
		padding-top:110px;
	}
}

@media screen and (min-width: 1152px){
	.pd {
		padding-top:95px;
	}
	.pd-news {
		padding-top:5px;
	}
}

@media screen and (min-width: 1600px){
	.pd {
		padding-top:110px;
	}
	.pd-news {
		padding-top:10px;
	}
}



.make-an-enquiry {

  font-size: 16px;

  &,
  &:first-child {
    padding-top: 0;
  }

  h1,
  h2 {
    @include outer-wrap;
    @include inner-wrap;
  }

  h6 {
    margin-bottom: 0;
  }

  label {
    font-family: $base-font-family;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.78;
    margin-bottom: 0;
  }

  .island {
    padding-top: 39px;
  }

  @include media($tablet) {

    .island {
      padding-top: 83px;
    }

    .contact-left {
      @include span-columns(6 of 12);
      @include shift();
    }

    .contact-right {
      @include span-columns(5 of 12)
    }
  }

  @include media($desktop) {

    .island {
      padding-top: 96px;
    }

  }

  .get-in-touch {
    width: 100%;

    label {
      font-weight: bold;
    }
  }
}
.cta {
  &--chevron {
    font-weight: bold;

    &:before {
      content: "";
      display: inline-block;
      background-image: url(../img/sprite.png);
      background-position: -452px -219px;
      margin: 0 5px 0 0;
      width: 12px;
      height: 21px;
      position: relative;
      top: 6px;
      left: -3px;
    }
  }
}

.section-intro {
  display: block;
  position: relative;

  @include media($mobile) {
    margin-bottom: 0;
  }

  @include media($tablet) {
    margin-top: 65px;
  }

  hr {
    border-bottom: 5px solid $dark-blue;
    margin-top: 0;
    margin-bottom: 24px;
  }

  .col-12 {
    @include span-columns(12 of 12);

    .heading {
      color: #003c71;

      @include media($tablet){
        font-size: 48px;
      }
    }
  }

  &--slim {
    padding: 0 30px;

    @include media($desktop){
      padding: 0 100px;
    }
  }
}

/* Surface Duo Portrait */
/* Surface Duo Landscape */
/* google 2xl*/
/* iphone X , XS, 11 Pro, 12 Mini */
/* iphone X , XS, 11 Pro, 12 Mini */
/* iPad Pro Portrait */
/* iPad Pro Landscape */
/* HiDPI LAPTOP*/
.content-card__card {
	background: #fff;
	width: 70%;
	top: 32%;
	height: auto;
	font-size:16px;
	position: absolute;
}
.content-card__card-body {
	padding: 20px 30px;
	text-align: left;
	.card-title {
		color: #003c71;
		line-height: 23px;
	}
	.card-text {
		padding: 0 20px;
		margin: 20px 0;
		color: #003c71;
		font-family: "FSAlbert";
		&.web-kit-clamp {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			overflow: hidden;
			padding: 0 20px 0 0;
			margin: 10px 0;
		}
	}
}
.shortlist {
	margin-left: auto;
	margin-right: auto;
	.item2 {
		width: 100%;
		&:last-child {
			padding: 0 20px;
		}
	}
	.card-text {
		padding: 0 20px;
		margin: 20px 0;
		color: #003c71;
		font-family: "FSAlbert";
		&.web-kit-clamp {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			overflow: hidden;
			padding: 0 12px;
			margin: 10px 0 10px;
		}
	}
	.text-link {
		padding: 0 10px !important;
	}
	&::after {
		clear: both;
		content: "";
		display: block;
	}
}
.content-card__card-link.text-link {
	padding: 0 20px 0px 30px;
	bottom: -20px;
}
.content-card__card-link {
	display: flex;
	font-size: 17px;
	font-family: "FSAlbertBold";
	color: #003c71;
}
.text-link {
	&:after {
		content: '';
		background: url(../../../assets/icons/arrow-right.svg);
		width: auto;
		min-width: 24px;
		height: 24px;
		display: inline-block;
		background-repeat: no-repeat;
		background-size: 24px;
		background-position: 0px;
		margin-left: 10px;
	}
}
.content-card {
	position: relative;
}
.content-card__card-header {
	text-transform: uppercase;
	display: table;
	span {
		padding: 2px 8px;
		color: #fff;
		font-size: 12px;
		font-family: "FSAlbertBold";
		letter-spacing: 1px;
	}
}
.content-card__card.bottom-txt {
	background: #fff;
	width: 70%;
	top: 71%;
	height: auto;
	position: absolute;
}
.card-grid {
	.btn {
		margin-top: 1px !important;
	}
}
.pd {
	padding-top: 125px;
}
.shortlist{
	margin-bottom:30px;
}
.green-banner{
	padding-top:70px !important;
}
.agig-main {
	margin-bottom:30px !important;
}
@media only screen and (min-width: 280px) {
    .content-card.m-bottom {
        margin-bottom: 33px;
    }
	.content-card__card {
		top: 26% !important;
		width: 90%;
	}
	.content-card__card-body {
		padding: 0px 30px;
	}
	.shortlist {
        padding-top: 2px !important;
	}
	.content-card__card-body .card-title {
		line-height: 13px;
	}
}
@media only screen and (max-width: 320px) {
	.content-card__card {
		top: 41% !important;
		
	}
	.shortlist {
		font-size: 12px;
		padding-top: 1px;
	}
	.shortlist .card-text {
		padding: 0 20px;
		margin: 35px 0;
  
	}
	.content-card__card-body .card-title {
        font-size: 13px !important;
		line-height: 20px !important;
	}
	.content-card__card.bottom-txt {
		top: 62% !important;
	}
	
	.pd {
		padding-top: 0px;
	}
}
@media only screen and (max-width: 360px) {
	.content-card__card {
		top: 36% !important;
		
	}
	.pd {
		padding-top: 0px;
	}
	.content-card__card-body .card-title {
    	line-height: 18px;
		font-size: 17px;
	}
	.content-card__card-body {
		padding: 11px 30px;
    }
	.content-card__card.bottom-txt {
		top: 56% !important;
		width: 92% !important;
    }
	.shortlist {
		padding-top: 1px;
	}
}

@media only screen and (min-width: 375px) {
	.content-card__card {
		top: 38% !important;
		
	}
	.content-card__card-body {
		padding: 10px 30px;
		text-align: left;
	}
	.pd {
		padding-top: 0px;
	}
	.shortlist {
		font-size: 15px;
	}
	.content-card__card.bottom-txt {
		top: 55% !important;
		width: 95% !important;
	}
	.content-card__card-body .card-title {
		color: #003c71;
		line-height: 21px;
		line-height: 24px !important;
	}
	
}
@media only screen and (min-width: 411px) {
	.content-card__card {
		top: 42% !important;
	}
	.content-card__card.bottom-txt {
		top: 59% !important;
	}
	.content-card__card-body {
		padding: 10px 30px;
    }
}
@media only screen and (min-width: 414px) {
	.content-card__card {
		top: 41% !important;
		width: 71% !important;
	}
	.content-card__card-body {
		padding: 10px 30px;
    }
}
@media only screen and (min-width: 540px) {
	.content-card__card {
		top: 55% !important;
	    width: 75% !important;
	}
	.content-card__card.bottom-txt {
		top: 76% !important;
	}
}

@media only screen and (min-width: 640px) {
	.content-card__card {
        padding: 0px 0px 0px;
	}
}
@media only screen and (min-width: 667px) {
	.content-card__card {
		top: 59% !important;
	}
	card__card.bottom-txt {
    top: 76% !important;
}
}
@media only screen and (min-width: 720px) {
	
}
@media only screen and (min-width: 736px) {
	.content-card__card {
		top: 63% !important;
	}
	card__card.bottom-txt {
    top: 76% !important;
}
}
@media only screen and (min-width: 768px) and (max-height: 1024px){
	.content-card.m-bottom {
		margin-bottom: 91px;
	}
	
	.content-card__card.bottom-txt {
		top: 74% !important;
		width: 72% !important
	}
	.shortlist {
		padding-top: 1px !important;
	}
}
@media only screen and (max-width: 800px) {
	.pd {
		padding-top: 0px !important;
	}
	.content-card__card {
		top: 56%;
	}
	.content-card__card.bottom-txt.position {
		position: relative;
	}
}
@media only screen and (min-width: 812px) {
	.content-card__card.bottom-txt.position {
		position: relative;
	}
}
@media only screen and (min-width: 823px) {
	.content-card__card.bottom-txt.position {
		position: relative;
	}
}
@media only screen and (min-width: 1024px) {
	.content-card__card {
		top: 18% !important;
		font-size: 11px;
		min-height: 75px;
		width: 75%;
	}
	.content-card__card-body {
		padding: 10px;
	}
	.shortlist {
		margin-bottom: 20px;
		font-size: 15px;
	}
}
@media only screen and (min-width: 1152px) {
	.content-card.m-bottom {
    margin-bottom: 122px;
	}
	.content-card__card {
		//top: 27% !important;
		top: 38% !important;
		font-size: 11px;
		min-height: 75px;
	}
	.content-card__card-body {
		padding: 10px;
	}
	.agig-hero-banner__content-wrapper {
		>div {
			p {
				font-size: 20px;
			}
		}
	}
	.content-card__card.bottom-txt.position {
		position: absolute;
	}
	
}
@media only screen and (min-width: 1280px) {
	.content-card__card {
		top: 37%;
		font-size: 16px;
	}
	.content-card__card-body {
		padding: 20px 30px 0;
	}
}
@media only screen and (min-width: 1360px) {
	.content-card__card {
		top: 38%;
	}
}
@media only screen and (min-width: 1366px) {
	.content-card__card {
    top: 33% !important;
	}
	.content-card__card-body .card-title {
		font-size: 1.375em;
		line-height: 0.91em;
	}
	.content-card__card.bottom-txt.position {
	position:absolute;
	}
}
@media only screen and (min-width: 1600px) {
	.content-card__card {
		top: 38% !important;
	}
	.content-card__card.bottom-txt {
       width: 80% !important;
	}
	.content-card__card.bottom-txt.position {
	position:absolute;
	}
}


.slim-banner {
  min-height: 220px;
  position: relative;
  top: 67px;

  @media screen and (min-width: $mobile-screen) {
    min-height: 320px;
  }

  @include media($desktop-lg) {
    top: 100px;
  }

  &:before {
    display: none;
  }

  &__image {
    position: absolute;
    width: 100%;

    &-wrap {
      height: 320px;
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover; object-position: center center';
      width: 100%;

      @media screen and (max-width: 500px) {
        height: auto;
      }
    }
  }

  &__container {
    @include span-columns(12 of 12);
    justify-content: center;
    display: flex;
    position: relative;
    top: 70px;
    margin-bottom: 70px;

    @media screen and (max-width: 400px) {
      top: 40px;
      margin-bottom: 50px;
    }

    @include media($tablet) {
      margin-bottom: 100px;
    }

    @include media($desktop-lg) {
      margin-bottom: 130px;
    }

    &-image {
      width: 91%;
      display: flex;
      height: 312px;
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover; object-position: center center;';

      @include media($desktop-lg) {

        @include span-columns(7 of 12);
        display: flex;
      }

      @media screen and (min-width: 1024px) and (max-width: 1259.98px) {
        width: 75%;
      }

      @media screen and (max-width: 500px) {
        height: 200px;
      }
    }
  }
}

.green-banner {
  padding-top: 50px;

  @include media($desktop) {
    padding-top: 100px;
  }

  &__content-wrapper {
    padding: 32px 24px;
    margin: 0 24px;
    background-image: linear-gradient(344deg, #34b98d, #01b991);
    color: white;
    display: flex;
    flex-wrap: wrap;

    &:hover {
      color: white;
    }

    @include media($desktop) {
      padding: 48px 80px;
    }
  }

  .col-2,
  .col-10 {
    flex-basis: percentage(12/12);
    max-width: percentage(12/12);
  }

  .col-2 {
    order: -1;
    display: flex;
    align-items: flex-start;

    @include media($desktop) {
      order: 10;
      flex-basis: percentage(2/12);
      max-width: percentage(2/12);

      justify-content: center;
      align-items: center;
    }

    img {
      max-width: 50px;
      margin-bottom: 24px;
      height: auto;

      @include media($desktop) {
        margin-bottom: 0;
        max-width: 120px;
      }
    }
  }

  .col-10 {
    @include media($desktop) {
      flex-basis: percentage(10/12);
      max-width: percentage(10/12);
    }
  }

  &__title {
    color: inherit;
    font-weight: 800;
    font-family: $albert-extra-bold;
    line-height: 1.2;
    margin-bottom: 8px;
    font-size: 26px;

    @include media($desktop) {
      font-size: 40px;
    }
  }

  &__content {
    margin-bottom: 32px;

    p {
      font-weight: 300;
      line-height: 1.4;
      margin-bottom: 24px;
      font-size: 24px;
      letter-spacing: -0.5px;

      @include media($desktop) {
        font-size: 40px !important;
      }

      &:last-of-type {
        margin: 0;
      }
    }
  }

  &__cta {
    font-weight: 800;
    font-family: $albert-extra-bold;
    text-transform: uppercase;
    font-size: 15px;

    @include media($desktop) {
      font-size: 17px;
    }

    &:before {
      content: "";
      display: inline-block;
      margin-right: 5px;
      @include sprite($arrow-right-white);
    }

    &:hover,
    &:focus {
      color: white;
      text-decoration: underline;
    }
  }
}

//Project Gallery Landing
.project-gallery-landing,
.project-gallery-landing:first-child{
  padding-top: 90px;
  .row{
    display: flex;
    flex-wrap: wrap;
    .columns-4{
      display: flex;
      flex-direction: column;
    }
  }
}

.gallery-imagetext {
    display: block;
    padding: 1px;
    transition: box-shadow 0.2s;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 15px;
    .gallery-imagetext__image {
        position: relative;
        cursor: pointer;
    }
    img{
      @include media($desktop) {
        height: 341px;
      }
      width: auto;
      opacity: 1;
      display: block;
      width: 100%;
    }
    p{
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        color: #083464;
        margin-top: 15px;
    }
    .gallery-thumbnail {
        position: relative;
        width: 100%;
        height: 100%;
        margin-bottom: 10px;
        overflow: hidden
    }
    
    .gallery-thumbnail > img {
        max-width: 100%;
        transition: all 0.9s ease
    }
    .gallery-thumbnail .gallery-thumbnail_link {
        position: absolute;
        background: rgba(1,1,1,0.8);
        color: #fff;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 0.3s ease;
        text-align: center;
        z-index: 2
    }
    
    .gallery-thumbnail:hover > img,
    .gallery-thumbnail:active > img  {
       transform: scale(1.05    )
    }
    
    .gallery-thumbnail:hover > .gallery-thumbnail_link,
    .gallery-thumbnail:active > .gallery-thumbnail_link {
        opacity: 1
    }
    .gallery-thumbnail_link .view-gallery{
        color: white;
        font-size: 16px;
        padding: 16px 32px;
        background: #1497D9;
        border-radius: 100px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        position: relative;   
        top: 47%;
    }
    
}

// Gallery details page

.project_gallery_details,
.project_gallery_details:first-child{
  padding-top: 0;
  .row{
    display: flex;
    flex-wrap: wrap;
    .columns-3{
      display: flex;
      flex-direction: column;
    }
  }
}

.back_to_gallery{
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #083464;
  margin-bottom: 35px;
  padding: 0 7.5px;
  cursor: pointer;
  span:before{
    width: 15px;
    height: 15px;
    background: {
      image: url("../img/arrow-left.png");
      size: contain;
      repeat: no-repeat;
      position: center;
    }
    content: '';
    right: 20px;
    margin-right: 10px;
    display: inline-block;
  }
} 

.gallery_heading{
  margin-top: 35px;
  margin-bottom: 50px;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 41px;
  color: #083464;
  padding: 0 7.5px;
}

.gallery_detail{
  display: none;
  margin-left: 7.5px;
  margin-right: 7.5px;
  margin-bottom: 30px;
  margin-top: 0;
  p{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #083464;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  .gallery_detail_image{
    img{
      height: 138px;
      margin: 0 auto;
      width: auto;
      cursor: pointer;
      @include media($tablet) {
        height: 220px;
      }
    }
  }
}


.load_more_wrap{
  margin: 0 auto;
  margin-top: 40px;
  display: table;
  .btn-load-more{
    height: 50px;
    width: 200px;
    background: #033B70;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
  }
}

.modal-wrap{
  .modal {
    text-align: center;
    padding: 0!important;
    z-index:100000;
    .modal-dialog{
      display: inline-block;
      text-align: left;
      vertical-align: middle;
      max-width: 100% !important;
      @include media($desktop) {
        width: 1197px !important;
      }
      .modal-body{
        position: relative;
        padding: 50px;
        a {
          cursor: pointer;
        }
        .img-responsive{
          @include media($desktop) {
            height: 747px;
          }
          height: auto;
          width: auto;
          margin: 0 auto;
          display: table;
        }
      }
    }
    .previous{
      width: 35px;
      height: 35px;
      position: absolute;
      top: 50%;
      left: 1%;
      background: {
        image: url("../img/chevron-left.png");
        size: contain;
        repeat: no-repeat;
        position: center;
      }
    }
    
    .next{
      width: 35px;
      height: 35px;
      position: absolute;
      top: 50%;
      right: 1%;
      background: {
        image: url("../img/chevron-right.png");
        size: contain;
        repeat: no-repeat;
        position: center;
      }
    }
    
    .modal-close-action{
      width: 15px;
      height: 25px;
      position: absolute;
      top: 10px;
      right: 15px;
      background: {
        image: url("../img/close-icon.png");
        size: contain;
        repeat: no-repeat;
        position: center;
      }
    }
    .modal-content{
      border-radius: 0;
    }
    .gallery_current_image{
      @include media($desktop) {
        height: 747px;
      }
      height: auto;
      margin: 0 auto;
      width: 100%;
    }
  }
  
  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px; /* Adjusts for spacing */
  }
} 

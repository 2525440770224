footer {
  overflow: hidden;

  .foot {
    height: auto;
    line-height: normal;
    padding: 24px;

    &-left {
      font-size: 16px;
    }

    span {
      display: block;
      width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
      text-align: center;
    }

    &-right {
      a,
      li:before {
        font-size: 18px;
      }
    }
  }

  .container {
    @include media($tablet) {
      display: flex;
      align-items: center;
    }
  }

  @include media($tablet) {
    .foot {
      padding-top: 30px;
      padding-bottom: 30px;

      ul {
        width: 100%;
      }

      span {
        margin-bottom: 0;
        text-align: left;
      }
    }
  }
}

.office-details {
  background-color: $dark-blue;
  padding: 44px 0 0;

  @include media($desktop) {
    padding: 64px 0 20px;
  }

  .container > div {
    margin: 0 24px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0;

    @include media($tablet) {
      margin-left: -32px;
      margin-right: -32px;

      > div {
        padding-left: 32px;
        padding-right: 32px;
      }
    }
  }

  .office {
    flex: 0 0 100%;
    max-width: 100%;

    margin-bottom: 44px;

    @include media($tablet) {
      flex: 0 050%;
      max-width: 50%;
      margin-bottom: 32px;
    }

    @include media($desktop) {
      flex: 0 0 25%;
      max-width: 25%;
    }

    &__image {
      margin-bottom: 12px;

      @include media($desktop) {
        margin-bottom: 32px;
      }

      img {
        max-width: 180px;

        @include media($tablet) {
          max-width: 220px;
        }
      }
    }

    &__details {
      color: $white;
      font-size: 18px;
      line-height: 1.56;

      a {
        color: inherit;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }
}

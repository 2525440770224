.row {
  @include row;
  margin-bottom: 20px;

  .columns-4 {
    @include media($desktop) {
      float: left;
      display: block;
      width: 33.33%;
      margin-right: 0;

      &.columns-padded {
        padding: 0 20px;
      }
    }
  }
  .columns-3 {
    
      float: left;
      display: block;
      width: 50%;
      margin-right: 0;
      &.columns-padded {
        padding: 0 20px;
      }
   
    @include media($desktop) {
      float: left;
      display: block;
      width: 25%;
      margin-right: 0;
      &.columns-padded {
        padding: 0 20px;
      }
    }
  }
}

section {
  padding: 0 0 20px 0;

  &.breadcrumbs {
    padding-bottom: 30px !important;
  }

  @include media($tablet) {
    padding: 0 0 40px 0;

    &:first-child {
      padding-top: 40px;
    }
  }

  @include media($desktop) {
    padding: 0 0 80px 0;
  }

  @include media($desktop-lg) {
    padding: 0 0 120px 0;

    &:first-child {
      padding-top: 120px;
    }
  }
}

.content {
  padding-top: 85px;

  @include media($tablet) {
    padding-top: 35px;
  }

  p {
    text-align: left;

    &:last-child {
      margin-bottom: 10px;
    }
  }
}

.btn {
  font-weight: 800;
  background-color: $dark-blue;
  font-weight: bold;
  letter-spacing: 0.3px;
  margin: 0;
  border-radius: 0;
  font-size: 15px;
  padding: 12px 16px;

  &-secondary {
    background-color: $light-blue;

    &::after {
      display: none;
    }
  }

  @include media($tablet) {
    padding: 14px 24px;
    font-size: 17px;
  }
}

.text-center {
  text-align: center;
}

.caps {
  text-transform: uppercase;
}

h1 {
  font-size: 38px;
  line-height: 40px;
}

h6 {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.78;
}

p {
  &.small {
    font-size: 16px;
    letter-spacing: 0.3px;
    margin: 0;
  }
}

a {
  color: #33b5e6;
}

.agig-main {
  margin-bottom: 96px;

  @include media($mobile) {
    margin-bottom: 128px;
  }
}

.agig-article-main {
  background-color: #fafcff;
  top: 80px;

  .content {
    padding-top: 0;
  }
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

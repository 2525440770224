.primary-content-card {
	.prime {
		.content-card__card-body {
			.card-text {
				color: #003c71;
			}
		}
	}
}

.pull-right {
	float: right;
	margin-top: 26px;
	color: white;
	font-size: larger;
}
.p-text {
	float: left;
}
.agig-hero-banner__content-wrapper {
	&.margin-thirty {
		margin: 30px 0 -30px !important;
	}
    padding-bottom: 120px !important;
}

@media screen and (max-width: 768px) {
.agig-hero-banner__content-wrapper {
	padding-bottom: 30px !important; 

}
}


@media screen and (max-width: 480px) {
	.pull-right {
		float: right;
	}
	.card-header.bg-light-blue {
		width: 34%;
	}
	.hero-banner {
		.card {
			.card-body {
				.card-text {
					font-size: 28px !important;
					line-height: 1.4em !important;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
				}
			}
		}
		padding: 63px 0;
	}
}
@media only screen and (min-width: 540px) {
	.pull-right {   
		margin-top: -18px;    
	}
}

@media only screen and (min-width: 1020px) {
	.pull-right {   
		margin-top: 50px;    
	}
}

.company-module {
  &:not(:last-child) {
    margin-bottom: 25px;
    @include media($desktop) {
      margin-bottom: 0;
    }
  }
  img {
    max-width: 320px;
    width: 100%;
    @include media($desktop) {
      max-width: 100%;
    }
  }
  p[data-equalheight-element] {
    padding-bottom: 30px;
    margin-bottom: 0;
  }
}

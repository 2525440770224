// Grab libs from bower
@import "lib/normalize";
@import "lib/bourbon";
@import "lib/neat";

// Base Setup
@import "fonts";
@import "sprites";
@import "agig/base/base";
@import "base/base";
@import "mixins";
@import "global";


// styles for generic columns
@import 'agn/generic-columns';


@import "agig/new-hero-banner";
@import "agig/home-news-media";

@import "agig/company-module";
@import "agig/contact";
@import "agig/cta";
@import "agig/footer";
@import "agig/global";
@import "agig/header";
@import "agig/header-features";
@import "agig/hero-banner";
@import "agig/hero-banner-feature";
@import "agig/imagetext";
@import "agig/typography";
@import "agig/who-we-are";
@import "agig/404";
@import "agig/compliance";
@import "agig/cards";
@import "agig/slim-banner";
@import "agig/section-intro";
@import "agig/article-body";
@import "agig/card-grid";
@import "agig/green-banner";
@import "agig/gallery";

// styles for pipeline page
@import "agig/pipelines";

.flex-grid {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.contact {
  &.flex-grid {
    @include media($tablet) {
      -ms-flex-direction: row;
      -webkit-flex-direction: row;
      flex-direction: row;
    }
  }
  .flex-grid-item {
    margin-bottom: 12px;
    @include media($tablet) {
      margin: 20px 0;
      //flex-grow: 1; IE10 doesn't support
      width: 33.33%;
    }
  }
  &-name, &-title, &-number, &-email {
    display: block;
    margin-bottom: 12px;
    @include media($tablet) {
      margin-bottom: 5px;
    }
  }
  &-name, &-email a {
    font-weight: bold;
  }
}

.pipelines {
  .filter {
    margin: 30px 0;
	padding: 0;
    @include media($tablet) {
      margin-left: auto;
      margin-right: auto;
      width: 570px;
    }
    li {
      width: 50%;
    }
  }
  h1 {
    font-family: FSAlbert;
    font-size: 18px;
    font-weight: bold;
    color: #003c71;
  }

  main {
    border: 2px solid #f3f6f9;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .flex-grid {
    @include media($desktop) {
      border-top: 2px solid #f3f6f9;
      -ms-flex-direction: row;
      -webkit-flex-direction: row;
      flex-direction: row;
    }
    &.header {
      display: none;
      @include media($desktop) {
        display: flex;
      }
    }
    &:nth-child(odd) {
      background: rgba(243, 246, 249, .3);
    }
    &:nth-child(1) {
      background: transparent;
      border: 0;
      @include media($desktop) {
        .flex-grid-item {
          .label, .label.tooltip {
            display: block;
          }
        }
      }
    }
    @include media($desktop) {
      .flex-grid-item {
        .label, .label.tooltip {
          display: none;
        }
      }
    }
  }
  .flex-grid-item {
    border-top: 2px solid #f3f6f9;
    padding: 12px;
    &:last-child{
      a {
        font-family: FSAlbert;
        color: #00a3e0;
        &:before {
          content: '\203A';
          font-size: 1.6em;
          line-height: 1;
          margin-right: 10px;
        }
      }
    }
    @include media($desktop) {
      border-left: 2px solid #f3f6f9;
      border-top: 0;
      width: 14%;
      &:nth-child(2) {
        width: 28%;
      }
      &:nth-child(3) {
        width: 20%;
      }
      &:nth-child(4) {
        width: 10%;
      }
    }
    &:first-child {
      border: 0;
    }
    .label {
      display: block;
      font-size: 12px;
      font-weight: bold;
      opacity: .5;
      text-transform: uppercase;
      &.tooltip {
        display: inline-block;
        position: relative;
        &::after {
          background: url(../img/tooltip.svg);
          content: ' ';
          display: inline-block;
          height: 14px;
          margin-left: 5px;
          vertical-align: middle;
          width: 14px;
        }
        .popup {
          display: none;
        }
        &:focus, &:active, &:hover {
          opacity: 1;
          outline: 0;
          .popup {
            border: 1px solid #d9d9d9;
            display: block;
            font-size: 15px;
            position: absolute;
            left: -12px;
            transform: translateY(-160%);
            width: calc(100vw - 22px);
            padding: 10px;
            background: #fbfbfb;
            border-radius: 5px;
            box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.04);
            text-transform: none;
            z-index: 1;
            @include media($desktop) {
              left: auto;
              max-width: 225px;
              padding: 15px;
              top: -10px;
              right: -230px;
              width: auto;
              transform: none;
            }
            &:before{
              content: '';
              position: absolute;
              border-style: solid;
              border-width: 10px 10px 0;
              border-color: #d9d9d9 transparent;
              width: 0;
              z-index: 1;
              bottom: -10px;
              left: 110px;
              @include media($desktop) {
                border-color: transparent #d9d9d9;
                border-width: 10px 10px 10px 0;
                left: -10px;
                top: 10px;
                bottom: auto;
              }
            }
            &:after{
              content: '';
              position: absolute;
              border-style: solid;
              border-width: 9px 9px 0;
              border-color: #fbfbfb transparent;
              width: 0;
              z-index: 1;
              bottom: -8px;
              left: 111px;
              @include media($desktop) {
                border-color: transparent #fbfbfb;
                border-width: 9px 9px 9px 0;
                left: -8px;
                top: 11px;
                bottom: auto;
              }
            }
          }
        }
      }
    }
    .content {
      font-size: 14px;
      margin: 0;
      padding: 0;

	  a {
		font-size: 14px;
	  }

      .title {
        font-weight: bold;
      }
    }
  }

  a {
    font-weight: bold;
  	&.with-underline {
  	  text-decoration: underline;
  	}
    &.with-arrow {
      font-family: FSAlbert;
      color: #00a3e0;
      &:before {
        content: '\203A';
        font-size: 1.6em;
        line-height: 1;
        margin-right: 10px;
      }
    }
  }
}

.content .additional-info {
  padding: 0;
  list-style: none;

  a{
    font-family: FSAlbert;
    font-weight: bold;
    color: #00a3e0;
    &:before {
      content: '\203A';
      font-size: 1.6em;
      line-height: 1;
      margin-right: 10px;
    }
  }
}

header.agig-header {
  background: white;
  border-bottom: $base-border;
  z-index: 999;

  .main {
    min-height: 0;
    height: auto;
    max-width: none;
    padding: 0;
    display: flex;
    justify-content: space-between;

    &:after {
      content: normal;
    }

    @include media($tablet) {
      justify-content: flex-start;
    }
  }

  .logo-wrapper {
    padding: 12px 24px;

    @include media($tablet) {
      border-right: $base-border;
    }

    @include media(new-breakpoint(min-width $desktop-screen + 1)) {
      padding: 32px 24px 35px 23px;
    }
  }

  .logo {
    width: 170px;
    height: 39px;
    display: block;
    margin: 0;
    position: relative;
    float: none;
    background: {
      image: url("../img/agig-logo.png");
      size: contain;
      repeat: no-repeat;
      position: center;
    }

    @include media(new-breakpoint(min-width $desktop-screen + 1)) {
      width: 200px;
      height: 41px;
    }

    @include media($desktop-lg) {
      width: 256px;
      height: 53px;
    }
  }

  .hamburger-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-left: $base-border;
    padding: 0 20px;

    @include media($tablet) {
      display: none !important;
    }
  }

  .hamburger {
    display: block;
    position: static;
    margin: 0;

    &:before {
      margin: 0;
    }
  }

  // Desktop nav styles
  nav {
    @include media($tablet) {
      flex: 1 0 0;
      display: flex;
      align-items: center;
    }

    > ul {
      position: relative;
      padding: 0 12px;
      justify-content: flex-start;

      @include media($tablet) {
        display: flex;
        // justify-content: space-around;
      }

      @include media($desktop) {
        padding: 0;
      }

      @include media($desktop-lg) {
        justify-content: flex-start;
      }

      > li {
        position: relative;
        display: flex;

        &.no-subnav {
          & > span,
          & > a {
            padding-right: 0;

            &:before,
            &:after {
              display: none;
            }
          }
        }

        @include media($tablet) {
          &:hover {
            & > span,
            & > a {
              &:before {
                background-position: -456px -258px;
                transform: translateY(-50%) scale(1, -1);
              }
            }

            // subnav

            > div {
              opacity: 1;
              z-index: 20;
              pointer-events: auto;
              left: 0;
              transition: all 0ms 0ms,
                opacity ($base-duration * 2) $base-timing 0ms;
            }
          }
        }

        > span,
        > a {
          padding: 24px 15px;
          position: relative;

          @include media($tablet) {
            // padding-left: 0;
            padding-right: 20px;
            font-size: 1.5vw;
          }

          // @include media(new-breakpoint(min-width $desktop-screen + 1)) {
          @include media($desktop) {
            padding: 44px 25px;
            font-size: 1em;
          }

          @include media($desktop-lg) {
            padding: 50px 30px;
            font-size: 1.125em;
          }

          &:before {
            position: absolute;
            right: 8px;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
            margin-top: 1px;
            background-position: -456px -258px;
            transition: $base-duration $base-timing;
            display: none;

            @include media($tablet) {
              display: block;
              right: 1px;
            }

            @include media(new-breakpoint(min-width $desktop-screen + 1)) {
              right: 5px;
            }

            @include media($desktop-lg) {
              right: 8px;
              width: 13px;
              height: 7px;
            }
          }
        }

        // subnav
        > div {
          display: block;
          top: 100%;
          margin: 0;
          overflow: hidden;
          min-height: 0;
          padding: 0 4px 4px;
          width: auto;
          background: transparent;

          // show/hide
          z-index: -500;
          pointer-events: none;
          opacity: 0;
          left: -999em;
          transition: all 0ms ($base-duration * 2),
            opacity ($base-duration * 2) $base-timing 0ms;

          ul {
            width: 100%;
            float: none;
            box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.1);
            background: $white;

            li {
              margin: 0;
              &.mobile-nav {
                display: block;

                @include media($tablet) {
                  display: none !important;
                }
              }
              &:first-child {
                font-family: $base-font-family;
              }

              a {
                white-space: nowrap;
                color: $dark-blue;
                padding: 20px 24px;
                display: block;
                font-size: 1.5vw !important; // to override first-child important
                // font-size: 1.5vw !important; // to override first-child important
                transition: $base-timing $base-duration;
                font-weight: bold;

                &:hover,
                &:focus {
                  background-color: #eef5f8;
                  color: $light-blue;
                }

                @include media($desktop) {
                  font-size: 16px !important; // to override first-child important
                }
              }
            }
          }
        }
      }
    }
  }

  // Mobile show nav
  @include media(max-width 767px) {
    nav {
      background-color: white;
      padding-top: 30px;
      padding-bottom: 30px;
      z-index: -500;
      opacity: 0;
      pointer-events: none;
      left: -999em;
      position: fixed;
      display: block;
      top: 64px;
      right: 0;
      bottom: 0;
      transition: all 0ms ($base-duration * 2),
        opacity ($base-duration * 2) $base-timing 0ms;

      > ul {
        > li {
          span,
          a {
            background: none;
            font-size: 18px;
            color: $dark-blue;
            line-height: 1.1;
            font-weight: bold;
            border: 0;
            text-transform: none;
          }

          > span,
          > a {
            padding: 12px 24px;
            font-weight: 800;
            font-family: $albert-extra-bold;

            &:after {
              background-position: -452px -219px;
              margin: 0;
              width: 12px;
              height: 21px;
            }
          }

          > div {
            background-color: white;
            position: fixed;
            top: 64px;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            pointer-events: none;
            z-index: 1000;
            transform: translateX(50px);
            transition: all 0s ($base-duration * 2), opacity ($base-duration * 2) $base-timing 0ms, transform ($base-duration * 2) $base-timing 0ms;

            ul {
              display: block;
              padding: 14px 0 0 0;

              li {
                &.mobile-nav {
                  a {
                    font-weight: 800;
                    font-family: $albert-extra-bold;
                  }

                  &--back a {
                    font-size: 15px !important;
                    color: $light-blue;
                    padding-top: 14px;
                    padding-bottom: 14px;
                    text-transform: uppercase;

                    &:before {
                      content: "";
                      background-image: url(../img/sprite.png);
                      display: block;
                      background-position: -452px -219px;
                      margin: 0 5px 0 0;
                      width: 12px;
                      height: 21px;
                      transform: scale(-1, 1);
                      float: left;
                    }
                  }

                  &--section-title a {
                    font-size: 18px !important;
                    padding-top: 14px;
                    padding-bottom: 14px;
                  }
                }

                a {
                  padding-top: 14px;
                  padding-bottom: 14px;
                  font-size: 16px !important;
                }
              }
            }

            &.show {
              opacity: 1;
              pointer-events: auto;
              transform: translateX(0);
              transition-delay: 0ms, 0ms, 0ms;

              ul {
                box-shadow: none;
              }
            }
          }
        }
      }
    }

    &.show-nav {
      nav {
        opacity: 1;
        pointer-events: auto;
        left: 0;
        z-index: 999;
        transition: all 0ms 0ms, opacity ($base-duration * 2) $base-timing 0ms;
      }
    }
  }
}

.sitemap.sitemap--agig {
  li {
    color: #33b5e6;
    list-style-position: inside;
    list-style-type: disc;
    margin: 1em 0;

    a {
      display: inline;
      font-family: $base-font-family;
      font-size: 18px;
      font-weight: normal;
      padding: 0;
    }

    li {
      display: list-item;
      margin: 1em 1.5em;
      padding: 0;

      li {
        list-style: none;
        &::before {
          content: '-';
          display: inline;
          padding-right: 15px;
        }
      }
    }
  }
}

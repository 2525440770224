$gutter: 20px;

.header-feature-agig{
    box-shadow: inset 0 -1px 0 0 #e7e7e7;
    .logo-wrapper {
      padding: 12px 24px;
    
      @include media($tablet) {
        border-right: $base-border;
        padding-left: 7px;
        padding-right: 7px;
      }
      /* ----------- iPad Pro  Portrait and Landscape ----------- */
      @media only screen 
      and (min-width: 1024px) 
      and (max-height: 1366px) 
      and (-webkit-min-device-pixel-ratio: 1.5) {
        padding: 38px 24px;
      }

      @include media(new-breakpoint(min-width $desktop-screen + 1)) {
        padding: 32px 24px 35px 23px;
      }
    }
    
    .agig-logo {
      width: 170px;
      height: 39px;
      display: block;
      margin: 0;
      position: relative;
      float: none;
      background: {
        image: url("../img/agig-logo.png");
        size: contain;
        repeat: no-repeat;
        position: center;
      }
    
      @include media(new-breakpoint(min-width $desktop-screen + 1)) {
        width: 200px;
        height: 41px;
      }
    
      @include media($desktop-lg) {
        width: 256px;
        height: 53px;
      }
    }
    
    .menus-list{
      .menus{
        line-height: 60px;
        margin-left: -5px;
      }
    }
    
    
    @media screen and (min-width: 1024px){
      nav>ul>.toggle-menu.show-nav>.menus_first-level {
          top: 70px !important;
      }
    }
    
    @media screen and (max-width: 1023.98px) and (min-width: 768px){
      .menus-list{
        .menus{
          line-height: 31px;
        }
      }
    }
  
  
    
    .menus_first-level, .menus_secondary-level{
      li a {
        font-weight: normal;
      }
    }
      
      .show-desk{
        display: none;
      }
      .show-mob{
        display: block;
      }
  
    @include media($tablet) {
      .show-mob{
        display: none;
      }
      .show-desk{
        display: block;
      }
    }
    
  }
  
  
  // same style like AGN
  // Header
  $header-spacing: 30px;
  
  header.header-features {
    // All Media
    display: flex;
    position: fixed;
    width: 100%;
    color: $dark-blue;
    z-index: 99999;
    background: white;
    top: 0;
  
    .header-logo {
      @include span-columns(2 of 12);
      box-shadow: inset -1px -1px 0 0 #e7e7e7;
      display: flex;
      padding: 24px;
      margin-right: 0;
      align-items: center; }
  
    .header-main {
      @include span-columns(11 of 12);
      display: flex;
      flex-direction: column;
      margin-right: 0; }
  
    .main {
      background: #FFF;
      max-width: 100%;
      height: 80px;
      margin: 0;
      padding: 0;
      position: inherit;
      box-shadow: inset 0 -1px 0 0 #e7e7e7; }
  
    .logo {
      width: 157px;
      display: flex;
      position: relative;
      margin: 0;
      height: auto;
      float: none;
  
      img {
        height: 53px;
        width: auto; } }
  
    .fault:before {
      content: '';
      background: url('../../../assets/icons/fault.svg');
      width: 18px;
      height: 14px;
      display: inline-flex;
      background-repeat: no-repeat;
      margin-right: 5px; }
  
    .leak:before {
      content: '';
      background: url('../../../assets/icons/fire.svg');
      width: 14px;
      height: 14px;
      display: inline-flex;
      background-repeat: no-repeat;
      margin-right: 5px; }
  
    .enquiry:before {
      content: '';
      background: url('../../../assets/icons/phone-icon.svg');
      width: 14px;
      height: 14px;
      display: inline-flex;
      background-repeat: no-repeat;
      margin-right: 5px; }
  
    .caret-right:after {
      content: '';
      background: url('../../../assets/icons/caret-right.svg');
      width: 18px;
      height: 18px;
      display: inline-flex;
      background-repeat: no-repeat;
      position: absolute;
      right: 15px; }
  
    .location-state {
      display: flex;
      align-items: center;
      &:before {
        content: '';
        background: url('../../../assets/icons/map.svg');
        width: 14px;
        height: 14px;
        display: inline-flex;
        background-repeat: no-repeat;
        position: relative;
        margin-right: 5px;
        span {
          font-weight: bold; } }
      &:hover {
        text-decoration: none; } }
  
    .login-menu {
      display: flex;
      align-items: center;
      &:before {
        content: '';
        background: url('../../../assets/icons/navy.svg');
        width: 22px;
        height: 25px;
        display: inline-flex;
        background-repeat: no-repeat;
        margin-right: 5px; } }
  
    .contact-line {
      @include span-columns(12 of 12);
      display: flex;
      max-width: 100%;
      margin: 0;
      padding: 0;
      box-shadow: inset 0 -1px 0 0 #e7e7e7;
  
      .fault, .leak, .enquiry {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0;
        font-size: 13px;
        box-shadow: inset -1px -1px 0 0 #e7e7e7; }
  
      .mob-phone {
        display: none; }
  
      .desk-phone {
        display: inline-block; } }
  
    .search {
      position: relative;
  
      >span {
        height: 100%; }
  
      input[type=search] {
        padding: 10px 40px;
        height: 100%;
        background: white !important;
        border-radius: 0;
        box-shadow: inset 1px -1px 0 0 #e7e7e7; }
  
      input[type=submit] {
        content: '';
        background: url('../../../assets/icons/search.svg');
        height: 25px;
        width: 25px;
        display: block;
        left: 9px;
        top: 32%;
        padding: 0;
        background-repeat: no-repeat;
        background-color: transparent;
        color: transparent;
        -webkit-appearance: none;
        position: absolute; } }
  
    .mobile-nav-search {
      display: inline-block;
     }
  
    @include media($tablet) {
      .main {
        .logo {
          display: none; } }
  
      .search {
        float: right;
        width: 200px;
        height: 80px;
        margin: 0;
  
        input {
          box-sizing: border-box; } }
  
      nav {
        display: inline-block;
        height: 100%;
        position: relative;
        @include media($tablet) {
          padding-left: 16px;
        }
        .back-button {
          display: none;}
  
        // #1 Level Nav
        > ul {
          position: relative;
          padding: 0;
          width: inherit;
          justify-content: space-around;
          display: flex;
          align-items: center;
          text-align: center;
          > .toggle-menu {
            display: inline-flex;
            position: relative;
            > a {
              padding: 29px 32px;
              font-size: 17px;
              @media only screen 
               and (min-width: 1024px) 
               and (max-height: 1366px) 
               and (-webkit-min-device-pixel-ratio: 1.5) {
                font-size: 14px;
                padding-left: 9px;
                padding-right: 9px;
              }
              font-family: $albert-bold;
              color: $dark-blue;
              cursor: default;
  
              &:hover {
                color: $light-blue; }
  
              &:before {
                content: '';
                display: none; } } } }
  
        // #2 Level Nav (Dropdown)
        > ul > .toggle-menu > div,
        > ul > .toggle-menu > div > ul > li > div {
          display: none;
          background: $white;
          position: absolute;
          width: 288px;
          left: 75px;
          padding: 50px 0 0 0;
          box-sizing: content-box;
          z-index: 1;
          min-height: 300px;
          box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
          // Standard Dropdown columns (4 elements)
          ul {
            text-align: left;
            width: 100%;
            li {
              margin-bottom: 0;
              &:hover {
                background-color: #eef5f8; }
              a {
                color: $dark-blue;
                line-height: 20px;
                font-size: 18px !important;
                font-family: $albert;
                padding: 22px 10px 22px 32px;
                display: block;
  
                &:hover {
                  color: $light-blue; } }
  
              .menus_secondary {
                cursor: default; } } } }
  
        > ul > .toggle-menu.show-nav > div,
        > ul > .toggle-menu.show-nav > div > ul > li.show-nav > div {
          display: block;
          top: 10px;
          @include media($tablet) {
            top: 20px;
          } 
        }
  
        > ul > .toggle-menu.show-nav > div > ul > li.show-nav > div {
          left: 288px;
          top: 50px;
          padding-top: 0;
         }
       }
  
      .contact-line {
        line-height: 40px;
        vertical-align: -1px;
        font-size: 13px;
  
        // State Locater Dropdown, Login
        .location-dropdown {
          background: $white;
          position: absolute;
          display: none;
          z-index: 99;
          right: 10px;
          top: 40px;
          box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.1);
  
          li {
            &:hover {
              background-color: #eef5f8; }
            a {
              color: $dark-blue;
              display: block;
              text-align: left;
              padding: 0 25px;
              width: 150px; }
            &:hover {
              text-decoration: none;
              color: $light-blue; } }
          &.show {
            display: block; } }
        .login-dropdown {
          right: 60px; }
  
        // State Locator toggle
        .location {
          position: absolute;
          right: 0;
          display: flex;
          justify-content: flex-end;
          box-shadow: none;
  
          > a {
            color: $dark-blue;
            text-decoration: none;
            margin-right: 5px;
            margin-left: 5px; } }
  
        .desk-phone {
          display: inline-block;
          color: $dark-blue;
          font-family: $albert-extra-bold;
          font-weight: bold;
  
          &:hover {
            color: $dark-blue; } } }
  
      .mobile-tail {
        display: none !important; } }
  
    ////// Desktop and Up /////
    @include media($desktop) {
      nav {
        > ul > .toggle-menu a {
          font-size: 1.125em; }
  
        > ul > .toggle-menu.show-nav > .menus_first-level {
          top: 30px;
  
          > ul > li > div {
            top: 30px; } } }
  
      .search {
        width: inherit;
        height: 100%; }
  
      .contact-line {
        .fault, .leak, .enquiry {
          width: 24%; }
  
        .location {
          > div {
            box-shadow: none;
            margin-right: 10px;
            > a {
              display: inline-flex;
              background-repeat: no-repeat;
              color: $dark-blue;
  
              &:hover {
                color: $light-blue;
                text-decoration: none; } } } } } }
  
    @include media($desktop-lg) {
      nav > ul {}
      .logo {
        width: auto;
        height: 53px;
        margin: 0; }
  
      .search {
        width: 340px;
        height: 100%;
        margin: 0; }
  
      .contact-line {
        .location {
          > div {
            margin-right: 32px; } } }
  
      .twitter-typeahead {
        width: 100%; } }
  
    //////// Mobile Only ////////
    @include media($mobile) {
      height: 64px;
  
      .header-logo {
        display: none; }
  
      .header-main {
        width: 100%; }
  
      .main {
        box-shadow: inset -1px -1px 0 0 #e7e7e7;
        overflow: hidden;
        min-height: auto;
  
        .logo {
          box-shadow: none;
          height: 100%;
          padding-left: 23px;
  
          img {
            height: 64px; } } }
  
      .mobile {
        color: $dark-blue;
        text-align: center;
        position: absolute; }
  
      .mobile.search-toggle {
        top: 0;
        right: 63px;
        display: flex;
        width: 63px;
        height: 64px;
        justify-content: center;
        align-items: center;
        box-shadow: inset 1px 0 0 0 #e7e7e7;
        &:before {
          content: '';
          background: url('../../../assets/icons/search.svg');
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          display: block;
          margin: 0 5px; } }
  
      .mobile.hamburger {
        top: 0;
        right: 0;
        margin: 0;
        display: flex;
        width: 63px;
        height: 64px;
        justify-content: center;
        align-items: center;
        box-shadow: inset 1px 0 0 0 #e7e7e7;
        &:before {
          content: '';
          background: url('../../../assets/icons/hamburger.svg');
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          display: block; } }
  
      // Mobile Contact line and location selector
      .contact-line {
        display: none;
  
        .mob-phone {
          display: inline-block;
          color: $dark-blue; }
  
        .desk-phone {
          display: none; }
  
        > span:first-child {
          margin-right: 6px; }
  
        // Mobile Location Selector
        .location {
          .location-dropdown {
            display: none;
            &.show {
              display: block;
              position: absolute;
              z-index: 99;
              background: $light-blue;
              right: 0;
              top: 0;
              width: 210px;
              padding: 10px 10px 0 10px;
              box-sizing: content-box; }
            a {
              color: #fff;
              width: 100px;
              margin: 0 10px 10px 0;
              display: block;
              float: left;
              background: $dark-blue;
              padding: 10px 0;
              font-family: $bree; }
            a.active {
              background: $blue; }
            li:nth-child(2n+0) a {
              margin-right: 0; } }
          .login-dropdown {
            display: none; } }
        .mob-phone {
          display: inline-block; }
        .desk-phone {
          display: none; } }
  
      ul {
        transition: all .3s ease; }
  
      .hide-nav {
        display: none; }
  
      .search {
        display: none;
        background: lightgrey;
        margin-top: -64px;
        position: absolute;
        height: 64px;
        width: 312px;
        padding: 0;
  
        input[type=search] {
          box-sizing: border-box;
          padding: 10px 10px 10px 70px; }
  
        input[type=submit] {
          top: 0;
          width: 63px;
          height: 64px;
          border-radius: 0;
          margin: 0;
          left: 0;
          box-shadow: inset 0 0 0 1px #e7e7e7;
          background-position: center; } }
  
      // Mobile Navigation
      nav {
        display: none;
        // #1 Level Nav
        > ul > .toggle-menu > a {
          background: white;
          color: $dark-blue;
          display: block;
          padding: 0 1.5em;
          line-height: 60px;
          font-family: $albert-bold;
          border: none;
          text-transform: none;
  
          &:active, &:hover, &:focus {
            color: $dark-blue; }
          &:after {
            content: '';
            @include retina-sprite($arrow-right-group);
            display: block;
            float: right;
            margin: 24px 0 0 0; } }
  
        > ul {
          li.back-button {
            a {
              color: $light-blue;
              transition: none;
              display: inline-flex;
              align-items: center;
              line-height: 60px;
              margin-bottom: -15px;
              padding: 0 1.5em;
              background: $white;
              border: none;
              text-transform: none;
  
              &:before {
                content: '';
                background: url('../../../assets/icons/chevron-left.svg');
                width: 20px;
                height: 20px;
                background-repeat: no-repeat;
                display: block; }
  
              &:after {
                display: none; } } } }
  
        > ul {
          .show-nav {
            .menus {
              font-weight: bold;
              font-family: $bree;
              font-size: 18px;
  
              &:after {
                content: '';
                display: none; } }
  
            .menus_first-level {
              transition: all .5s ease;
              transform: translateX(0%);
  
              ul > .show-nav {
                .menus_secondary {
                  font-weight: bold;
                  line-height: 60px;
                  font-size: 18px;
                  font-family: $bree;
  
                  &:after {
                    content: '';
                    display: none; } }
  
                .menus_secondary-level {
                  transition: all .5s ease;
                  transform: translateX(0%);
  
                  > ul {
                    display: block;
  
                    li {
                      margin-bottom: 10px; } } } } } } }
        > ul > li:last-child a {
          border-bottom: 0; }
        // #2 Level Nav
        > ul > li > .menus_first-level {
          background: white;
          transform: translateX(100%);
          > ul {
            display: none;
            padding: 0 $gutter;
            &:last-of-type {
              padding-bottom: 20px; }
            > li {
              font-size: 1.4em;
              margin-bottom: 10px;
              font-family: $albert; }
            a {
              color: $dark-blue;
              font-size: 15px;
              line-height: 2em; }
            a.current {
              color: #33b5e6; }
  
            // tertiary nav
            .menus_secondary-level {
              transform: translateX(100%);
              > ul {
                display: none; } } } }
  
        > ul > .show-nav > .menus_first-level > ul {
          display: block; } } }
  
  
    // On toggle, show the dropdown nav
    &.show-search {
      .search {
        display: block; }
  
      .mobile.hamburger {
        &:before {
          content: '';
          background: url('../../../assets/icons/close.svg');
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          display: block; } } }
  
    &.show-nav {
      nav {
        display: flex;
        flex-direction: column;
        height: 100vh;
        position: absolute;
        width: 100%;
        background-color: white; }
  
      .search {
        display: none; }
      .mobile.search-toggle {
        display: none; }
      .mobile.hamburger {
        box-shadow: none;
        &:before {
          content: '';
          background: url('../../../assets/icons/close.svg');
          width: 24px;
          height: 24px;
          background-repeat: no-repeat;
          display: block; } }
  
      // Mobile only tail
      .mobile-tail {
        position: absolute;
        width: 100%;
        bottom: 15%;
        a {
          background: inherit;
          line-height: 60px;
          text-decoration: none;
          display: inline-block;
          color: $dark-blue;
          padding: 0;
          width: 100%;
          align-items: center;
          font-weight: normal;
          text-transform: none;
          border: none;
  
          &:before {
            content: '';
            display: none; }
  
          b {
            font-family: $albert-extra-bold; } }
  
        li {
          font-size: 13px;
          box-shadow: inset 0 1px 0 0 #e7e7e7;
          padding-left: 24px;
          display: flex;
          align-items: center; }
  
        li:last-child a {
          border-bottom: none; }
  
        span {
          position: absolute;
          right: 24px;
          font-family: $albert-bold;
          color: $light-blue;
          text-transform: uppercase;
          font-size: 11px;
          letter-spacing: 1px;
          text-decoration: underline;
          background: inherit;
          width: auto;
          padding: 0;
          border: none;
  
          &:after {
            content: '';
            display: none; } }
        // Icons
        a:after {
          display: none; }
  
        .location-toggle {
          select {
            background: $white;
            -webkit-appearance: none;
            line-height: 60px;
            margin-bottom: 0;
            border-top: 1px solid #e7e7e7;
            padding: 0 0 0 3px;
            font-family: $albert-extra-bold;
            font-weight: bold;
            outline: none;
  
            option {
              color: $dark-blue;
              display: block;
              text-align: center;
              font-weight: bold;
              width: 150px; } }
  
          &.show {
            display: block; } }
  
        .login-menu {
          &:before {
            position: relative;
            left: -8px;
            margin-right: -3px; } } }
  
  
      @include media($mobile) {
        &.show-nav .mobile-tail {
          display: block;
          box-shadow: none; } }
  
      @include media($mobile-h) {
        .mobile-tail a,
        nav > ul > .toggle-menu > .menus {
          line-height: 50px; }
  
        .mobile-tail {
          .login-menu {
            margin-bottom: 15px; }
  
          .location-toggle select {
            line-height: 49px; } } } }
  
    @include media($tablet-lg) {
      .header-logo {
        width: 140px;
        padding: 10px; }
  
      .contact-line {
        .fault, .leak, .enquiry {
          width: 30%;
          font-size: 12px; }
  
        .location {
          width: 25%;
  
          div {
            height: 40px;
            width: 17%;
            display: flex;
            justify-content: center;
            box-shadow: inset -1px -1px 0 0 #e7e7e7;
            .login-menu, .location-state {
              span {
                display: none; } }
  
            .location-state:before {
              width: 10px;
              margin-right: 0; } } } }
  
      .main {
        height: 60px;
  
        .search {
          height: 60px; }
  
        nav> ul> .toggle-menu {
          a {
            font-size: 16px;
            padding: 18px 15px 23px;
          }
          .menus{
            @include media($tablet) {
              font-size: 14px;
              padding-left: 7px;
              padding-right: 7px;
            }
           
          }
        }
        // Hide back-link for Tablet/
        nav {
          .back-button {
            @include media($tablet) {
              display: none !important;
           } 
          } 
        } 
      }
    } 
  }
  
  
  
  
  
  
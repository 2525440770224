.project-container,
.news-container {
  padding: 48px 0 70px;
  border-radius: 4px;
  box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.16);
  margin-bottom: 80px;
  background-color: white;

  @include media($desktop) {
    padding: 48px;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    @media screen and (min-width: 900px) {
      margin-bottom: 0;
    }

    @media screen and (max-width: 899.98px) {
      padding: 20px;
    }
  }

  .col-4 {
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    &:hover {
      background-color: #f5f9fb;
      border-radius: 4px;
    }

    @media screen and (min-width: 900px) and (max-width: 1260px) {
      width: 50%;
      float: left;
      margin: 0 0 20px;
    }

    @include media($desktop-lg) {
      @include span-columns(4 of 12);
    }
  }

  .card {
    padding: 32px;
    margin: auto;

    @include media($desktop) {
      padding: 23px;
    }

    @include media($mobile) {
      padding-bottom: 10px;
    }

    &__image {
      img {
        border-radius: 4px;
        margin-bottom: 24px;
      }
    }

    &__body {
      margin-bottom: 40px;

      .card-title {
        font-size: 24px;
        line-height: normal;
        margin-bottom: 16px;
      }

      .card-sub-title {
        font-size: 18px;
        font-weight: normal;
        line-height: 1.56;
        margin-bottom: 16px;
        text-transform: uppercase;
      }
    }

    &__link {
      &::after {
        content: '';
        @include retina-sprite($arrow-right-group);
        background-position: -459px -221px;
        width: 10px;
        height: 12px;
        display: inline-block;
        margin-left: 10px;
      }
    }

    .news-date {
      margin: 32px 0 0;
    }

    .news-title {
      font-weight: bold;
    }
  }

  .btn-primary {
    @include media ($mobile) {
      display: inline-flex;
    }
  }
}


.content-card {
  display: block;

  & + & {
    @include media(max-width 1023px) {
      margin-top: 32px;
    }
  }

  &:hover,
  &:focus {
    .content-card__image {
      img {
        opacity: 0.8;
        transform: scale(1.05)
      }
    }

    .content-card__link {
      text-decoration: underline;
    }
  }

  &__image {
    margin-bottom: 16px;
    padding-bottom: 56.25%;
    background: black;
    overflow: hidden;
    position: relative;

    @include media($desktop) {
      margin-bottom: 24px;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      font-family: "object-fit: cover;object-position: center;";
      transition: opacity ($base-duration * 1.5) $base-timing, transform ($base-duration * 2) $base-timing;
    }
  }

  &__title {
    font-weight: 800;
    font-family: $albert-extra-bold;
    line-height: 1.2;
    margin-bottom: 16px;
    font-size: 20px;

    @include media($desktop) {
      font-size: 24px;
    }
  }

  &__text {
    color: #222;
    font-size: 1em;
    line-height: 1.5;
  }

  &__link {
    color: $light-blue;
    font-weight: 800;
    font-family: $albert-extra-bold;
    text-transform: uppercase;
    font-size: 15px;
    margin: 0;

    @include media($desktop) {
      font-size: 17px;
    }

    &:before {
      content: "";
      display: inline-block;
      background-image: url(../img/sprite.png);
      background-position: -452px -219px;
      margin: 0 5px 0 0;
      width: 12px;
      height: 21px;
      position: relative;
      top: 6px;
      left: -3px;
    }

    &:hover,
    &:focus {
      color: $light-blue;
    }
  }
}

//reduce porject section heading space
.margin-bottom-16 {
  margin-bottom: 16px !important;
}

.hero-banner {
  padding: 0 10px 45px 10px;
  text-align: left;

  @include media($tablet) {
    margin-top: 100px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @include media($desktop) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @include media($desktop-lg) {
    padding-left: 80px;
    padding-right: 80px;
    width: 100%;
  }

  &__container {
    position: relative;
    top: 175px;

    .container {
      box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.16);
      background-color: rgba(0, 60, 113, 0.85);
      color: white;
      max-width: 864px;

      .col-8 {
        @include span-columns(8 of 12);
      }
    }
  }

  &:before {
    display: none;
  }

  &__title {
    position: absolute;
    bottom: 40%;
    left: 0;
    right: 0;
    text-align: center;
  }

  img {
    height: auto;
    left: 50%;
    margin: 0;
    transform: translateX(-50%);
    width: auto;
  }

  .container {
    @include media($desktop-lg) {
      max-width: 864px;
    }
  }

  h1 {
    font-size: 54px;
    margin: 0;
    padding: 0;
    text-shadow: none;

    @include media($tablet) {
      font-size: 54px;
    }
  }

  p {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 20px;
    text-shadow: none;

    @include media($tablet) {
      font-size: 22px;
      line-height: 32px;
    }

    @include media($desktop-lg) {
      margin-bottom: 40px;
      width: 66%;
    }
  }

  &-header {
    display: none;

    @include media($tablet) {
      display: block;
      height: 160px;
    }

    @include media($desktop) {
      height: 282px;
    }
  }
}

.article-body {
  padding: 0 100px 100px;

  &:first-child {
    padding-top: 0px;
  }

  @media screen and (max-width: 1023.98px) {
    padding: 0 30px;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 1024px) and (max-width: 1259.98px) {
    padding: 0 20px 100px;;
  }

  .col-12 {
    @include span-columns(12 of 12);
    margin-right: 0;

    .heading {
      margin-bottom: 37px;

      @include media($desktop) {
        font-size: 32px;
        margin-top: 40px;
      }

      @include media($mobile) {
        margin-top: 32px;
      }

      @media screen and (min-width: 768px) and (max-width: 1023.98px) {
        margin-top: 50px;
      }
    }
  }

  .image-block {
    display: block;

    @include media($desktop) {
      width: 100%;
      display: flex;
    }

    .col-6 {
      width: 100%;

      @include media($desktop) {
        @include span-columns(6 of 12);
      }
    }

    img {
      margin: 10px 0;
      width: fit-content;
      object-fit: cover;
      font-family: 'object-fit: cover;';

      @include media($desktop) {
        height: 246px;
      }

      @media screen and (min-width: 768px) and (max-width: 1023.98px) {
        height: 364px;
      }
    }
  }

  .image {
    img {
      margin: 10px 0;
      width: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;';


      @include media($desktop) {
        height: 388px;
      }

      @media screen and (min-width: 768px) and (max-width: 1023.98px) {
        height: 364px;
      }
    }

  }
}

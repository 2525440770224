.agig-hero-banner {
  padding-top: 64px;

  @include media($desktop) {
    padding-top: 109px;
  }

  @include media($desktop-lg) {
    padding-top: 123px;
  }

  .container {
    z-index: 5;
    position: relative;
    margin-top: -70px;

    @include media($desktop) {
      margin-top: -120px;
    }
  }

  .js-object-fit picture,
  .js-object-fit img {
    width: 100%;
    height: 256px;
    object-fit: cover;
    object-position: center;
    font-family: "object-fit: cover;object-position: center;";
    max-height: 60vh;

    @include media($tablet) {
      height: auto;
    }
  }

  &__content-wrapper {
    background: {
      image: url("../img/agig-banner-blue.png");
      size: cover;
      position: center;
      repeat: no-repeat;
    }
    color: white;
    padding: 32px;
    margin: 0 24px;
    @include media($desktop) {
      padding: 80px;
    }

    > div {
      @include media($desktop) {
        width: 60%;
      }

      p {
        font-weight: 300;
        line-height: 1.4;
        margin-bottom: 24px;
        font-size: 18px;

        @include media($desktop) {
          font-size: 24px;
        }

        &:last-of-type {
          margin: 0;
        }
      }
    }
  }

  &__title {
    font-weight: 800;
    font-family: $albert-extra-bold;
    line-height: 1.2;
    letter-spacing: -0.5px;
    margin-bottom: 32px;
    font-size: 24px;

    @include media($desktop) {
      font-size: 48px;
    }
  }

  &__read-more {
    color: white;
    font-weight: 800;
    font-family: $albert-extra-bold;
    text-transform: uppercase;
    font-size: 15px;

    @include media($desktop) {
      font-size: 17px;
    }

    &:before {
      content: "";
      display: inline-block;
      margin-right: 5px;
      @include sprite($arrow-right-white);
    }

    &:hover,
    &:focus {
      color: white;
      text-decoration: underline;
    }
  }
}
.tag-auto-width {
	width: auto !important;
}
.banner-webkit-camp {
  display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
//updated color for who we are link
.color-update-to-navy {
	&:hover {
	  color: #003c71;
	}
  }

.link--australia-icon {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 10px;

  &::before {
    @include retina-sprite($australia-icon-group);
    content: '';
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
  }
  &::after {
    border: 1.25px solid $light-blue;
    border-bottom: 0;
    border-left: 0;
    content: '';
    display: inline-block;
    height: 7px;
    margin-left: 5px;
    transform: rotate(45deg);
    width: 7px;
  }
  &:hover::after {
    border-color: tint($light-blue, 20%);
  }
}